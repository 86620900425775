/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Nav from "./Nav"
import Header from "./header"
import Newsletter from "./Newsletter"
import Footer from "./Footer"
// import logo from "../images/logo.png"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <div css={{ display: "flex" }}>
        <aside
          css={{
            minHeight: "100vh",
            flex: 1,
            background: "var(--dark-bg)",
            "@media(max-width: 768px)": {
              maxWidth: 10,
              minWidth: 10,
              background: "var(--accent)",
            },
            color: "transparent",
          }}
        ></aside>
        <div
          css={{
            flex: 1,
            paddingTop: 40,
            paddingLeft: 30,
            paddingRight: 30,
            background: "var(--light-bg)",
            "@media(min-width: 769px)": {
              paddingLeft: 60,
              paddingRight: 0,
            },
          }}
        >
          <Nav />
          <Header siteTitle={data.site.siteMetadata.title} />

          <main>{children}</main>

          <div css={{ marginTop: 80 }}>
            <div
              css={{
                background: "white",
                position: "relative",
                border: "4px solid var(--dark-bg)",
                borderRadius: 4,
                padding: 8,
                "@media (min-width: 769px)": {
                  marginLeft: -60,
                  left: "-50%",
                },
              }}
            >
              <Newsletter />
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
