import { Link } from "gatsby"
import Logo from "./Logo"
import React from "react"

const Header = ({ siteTitle = "" }) => (
  <header css={{ marginBottom: `2rem`, display: "flex", alignItems: "center" }}>
    <div css={{ marginLeft: -270 }}>
      <Logo />
    </div>
    <div
      css={{
        padding: `1.45rem 1.0875rem`,
        paddingLeft: 135,
      }}
    >
      <h1
        css={{
          margin: 0,
          borderBottom: "3px solid var(--accent)",
          display: "inline-block",
        }}
      >
        <Link to="/" css={{ textDecoration: `none`, color: "inherit" }}>
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

export default Header
