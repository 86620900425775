import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

import Form from "./Form"

export default function Newsletter() {
  const [submitted, setSubmitted] = useState(false)

  async function handleSubmit({ email, name }) {
    await addToMailchimp(email, {
      FNAME: name,
      PATHNAME: window.location.href,
    })

    setSubmitted(true)
  }

  if (submitted) {
    return (
      <div css={{ margin: "0 auto", textAlign: "center" }}>
        Grazie! controlla l'email per confermare
      </div>
    )
  }

  return (
    <div css={{ margin: "0 auto" }}>
      <div css={{ textAlign: "center" }}>
        <h4 id="newsletter" css={{ margin: 0, fontSize: 30, paddingTop: 20 }}>
          Newsletter
        </h4>
        <p css={{ margin: 0 }}>Rimani Aggiornato sulle novita di milano.dev</p>
        <small>(la piattaforma non i singoli eventi)</small>
      </div>

      <div
        css={{
          paddingTop: 20,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Form onSubmit={handleSubmit} />
      </div>
    </div>
  )
}
