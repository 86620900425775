import React, { useState } from "react"
import styled from "@emotion/styled"

const Input = styled.input({
  "@media (min-width: 768px)": {
    width: "300px",
  },
  maxWidth: "100%",
  padding: 4,
})

export default function Newsletter({ onSubmit }) {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        onSubmit({ name, email })
      }}
    >
      <div>
        <label>
          <div css={{ fontWeight: "bold", fontSize: "80%" }}>
            <span>Nome</span>
          </div>
          <Input
            name="first_name"
            id="first_name"
            required
            aria-required
            placeholder="Bob"
            type="text"
            onChange={e => setName(e.target.value)}
            value={name}
          />
        </label>
      </div>
      <div css={{ marginTop: 8 }}>
        <label>
          <div css={{ fontWeight: "bold", fontSize: "80%" }}>
            <span>Email</span>
          </div>
          <Input
            name="email_address"
            id="email"
            aria-required
            required
            placeholder="unclebob@cleancode.com"
            type="email"
            onChange={e => setEmail(e.target.value)}
            value={email}
          />
        </label>
      </div>
      <div css={{ marginTop: 8 }}>
        <button
          data-element="submit"
          type="submit"
          css={{
            background: "var(--dark-bg)",
            color: "var(--light-text)",
            fontSize: 14,
            padding: "4px 8px",
            cursor: "pointer",
            borderRadius: 4,
          }}
        >
          Registrami
        </button>
      </div>
    </form>
  )
}
