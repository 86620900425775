import React from "react"
import { Link } from "gatsby"

import TwitterLink from "./TwitterLink"

export default function Nav() {
  return (
    <nav
      css={{
        opacity: 0.4,
        ":hover": {
          opacity: 1,
        },
        display: "flex",
        justifyContent: "flex-end",
        "@media(min-width: 769px)": {
          maxWidth: 620,
        },
        fontSize: 16,

        "@media(max-width: 769px)": {
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 10,
          opacity: 1,
          backgroundColor: "var(--dark-bg)",
          color: "#fff",
          justifyContent: "unset",
          boxShadow: "0 1px 5px 1px rgba(0,0,0,0.12)",
          overflow: "hidden",
          overflowX: "auto",

          "& > div": {
            margin: 0,
            flex: 1,
            textAlign: "center",

            "& a": {
              display: "block",
              padding: 14,
              whiteSpace: "nowrap",
            },
          },
        },
      }}
    >
      <div className="twitter-wrapper" css={{ marginLeft: 18 }}>
        <TwitterLink />
      </div>
      <div css={{ marginLeft: 18 }}>
        <Link to="/">eventi</Link>
      </div>
      <div css={{ marginLeft: 18 }}>
        <Link to="/about">about</Link>
      </div>
      <div css={{ marginLeft: 18 }}>
        <Link to="/newsletter">newsletter</Link>
      </div>
    </nav>
  )
}
