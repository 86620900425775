import React from "react"
import { FeedbackFish } from "@feedback-fish/react"

export default function Footer() {
  return (
    <footer
      css={{
        marginTop: 40,
        marginBottom: 20,
      }}
    >
      <div css={{ display: "flex" }}>
        <div>
          <a
            css={{
              opacity: 0.4,
              cursor: "pointer",
              ":hover": {
                opacity: 1,
              },
            }}
            target="_blank"
            href="https://github.com/kandros/milano.dev-data#how-to-contribute"
            rel="noopener noreferrer"
          >
            segnala evento mancante
          </a>
        </div>
        <div css={{ marginLeft: 24 }}>
          <a
            css={{
              opacity: 0.4,
              cursor: "pointer",
              ":hover": {
                opacity: 1,
              },
            }}
            href="mailto:ciao@milano.dev"
          >
            contattaci
          </a>
        </div>

        <div css={{ marginLeft: 24 }}>
          <FeedbackFish projectId="e700ce4649260f">
            <span
              css={{
                opacity: 0.4,
                cursor: "pointer",
                ":hover": {
                  opacity: 1,
                },
              }}
            >
              feedback
            </span>
          </FeedbackFish>
        </div>
      </div>
    </footer>
  )
}
